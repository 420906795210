import React from 'react';

/**
 * Assets
 */
import './styles/_index.scss';

/**
 * Components
 */
import Video from '@components/video/video';

const PersonVideo = ({image, children, video, playid}) => {
    return (
        <div className="person-video">
            {video ? 
            <Video playid={playid} src={video} image={image} height="380px" />
            :
            <div className="video-box video-only-img">
                <img src={image}/>
            </div>
            }
            {children}
        </div>
    )
}

export default PersonVideo
