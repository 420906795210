import React, { useEffect } from 'react'
import { Container, Row } from 'react-bootstrap';

/**
 * Assets
 */
import './styles/_index.scss'

const RowImgText = ({children}) => {

   

    return (
        <div className="row-image-text" data-aos="fade-up" data-aos-duration="1000">
            <Container>
                <Row className="align-items-center">
                    {children}
                </Row>
            </Container>
        </div>
    )
}

export default RowImgText
