import React, { useState,useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { Container, Modal } from 'react-bootstrap';
import classNames from 'classnames'
import 'intersection-observer';
import { useInView } from 'react-intersection-observer';
import { Close } from '../../components/icon/icon';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import Img from 'gatsby-image';
import PlayIcon from '@components/play-icon/play-icon';
import FsLightbox from 'fslightbox-react';
import ReactPlayer from 'react-player';
import {
    isIOS,
    isMobileOnly,
    isBrowser,
    isMobile
} from "react-device-detect";

/**
 * Assets
 */

import './styles/_index.scss';

/**
 * Components
 */
import HomeSearch from '@blocks/forms/home-search';
import Video from '@components/video/video';
import getVideoId from 'get-video-id';
import $ from "jquery";

const Wave = (props) => {

    const [show, setShow] = useState(false);
    const [toggler, setToggler] = useState(false);
    const [play, setPlayvideo] = useState(true);
    const [showHTMLVideo, setHTMLVideo] = React.useState(false);
    const [, setPlay] = React.useState(false);
    const [controls, setVideoControls] = useState(false);
    const [mute, setMute] = useState(true);
    const vidRef = useRef(null);
    const pausevideo = (ref) => {
        setPlayvideo(false)
        setVideoControls(false)

    }
    const playvideo = (ref) => {
        setPlayvideo(true)
        setVideoControls(false)
    }
    const showControls = () => {
        setVideoControls(true)
    }

    const url = typeof window !== 'undefined' ? window.location.href : '';
    const trackerVideo = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Video Play Btn',
            'formType': url,
            'formId': 'Video Play',
            'formName': 'Video',
            'formLabel': 'Video'
        });
    }

    const [ref, inView] = useInView({
        threshold: 0,
        triggerOnce: true,
    });
    const data = useStaticQuery(graphql`
        query {
                video: file(relativePath: { eq: "video-img.jpg" }) {
                    childImageSharp {
                    fluid(maxWidth: 2048) {
                    ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    const image_url = props.VideoBG.internal.description ? props.VideoBG.internal.description.replace("File ", "").replace('"', '').replace('"', '') : '';
    var video_url = ''
    if (props.HTMLVideo) {
        video_url = props.HTMLVideo.internal.description ? props.HTMLVideo.internal.description.replace("File ", "").replace('"', '').replace('"', '') : '';
    }
    if(props.VideoURL !=null && props.VideoURL !=''){
        var youtube_id = getVideoId(props.VideoURL);
        if(youtube_id.service === "youtube") {
            video_url      = "https://www.youtube-nocookie.com/embed/"+youtube_id.id;
        } else {
            video_url = props.VideoURL;
        }
    }
   
    useEffect(() => {
        setTimeout(function(){ 
            $('.wave').addClass('banneranimation');
        }, 1000);
        setTimeout(function(){ 
            $('.wave').removeClass('banneranimation');
        }, 10000);
    });
    return (
        <>
            {showHTMLVideo &&
                <ScrollAnimation animateIn='animate__zoomIn' animateOnce={true} duration={0.3} delay={300}>
                    <Modal
                        show={show}
                        onHide={() => setShow(false)}
                        dialogClassName="modal-video"
                        aria-labelledby="example-custom-modal-styling-title"
                        backdropClassName="video-backdrop"
                    >
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <ReactPlayer frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen ref={vidRef} onPlay={trackerVideo(props.Title)} url={video_url} modestbranding="1"  controls={isMobile && isIOS ?true:false} autoplay={true} muted={false} playsinline={true} playing={play} onEnded={() => { setHTMLVideo(false) }} width='100%' height='100%' />
                        </Modal.Body>
                    </Modal>
                </ScrollAnimation>

            }
            <div  className={classNames('wave', { '': inView })} ref={ref}>
                <ScrollAnimation offset={50} animateIn='animate__slideInLeft' animateOnce={true} duration={0.8} delay={300}>

                    <div className={classNames('wave__video video_enclose', { '': inView })}>
                        <Img fluid={props.VideoBG.childImageSharp.fluid} alt={props.Title+' - Anthony Pepe'}/>
                        {props.VideoURL &&
                        <button className="btn-play"
                            onClick={() => { setHTMLVideo(true); playvideo(); setShow(true); }}
                        >
                        
                            <PlayIcon />
                        </button>
                        }   
                    </div>

                </ScrollAnimation>

                <div className="wave-search">
                    <Container>
                        {props.Showsearch == true &&
                            <HomeSearch />
                        }
                    </Container>
                </div>
            </div>
        </>
    )
}

export default Wave
