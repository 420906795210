import React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'gatsby';
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw'
import GETLINK from '../getlink'
/**
 * Components
 */
import TextColumns from '@components/text-columns/text-columns';

const HomeTextColumns = (props) => {
    return (
        <div className="static-text-columns two-col-wrapper">        
        <TextColumns>
            <Col md={12} lg={5}>
                <ReactMarkdown rehypePlugins={[rehypeRaw]} children={props.ContentLeft}/>
                {props.childpages && props.childpages.length > 0 &&
                <ul className="child-pages">
                    {props.childpages.map((node, key) => {
                    return<>
                    {node.Child_Page_Custom_Link ?
                    <li data-aos="fade-right"><Link to={node.Child_Page_Custom_Link}>--- {node.Child_Page_Label}</Link></li>
                    :
                    <li data-aos="fade-right"><GETLINK label={node.Child_Page_Label} link={node.Child_Page_Link && node.Child_Page_Link.URL}/></li>
                    }
                    </>
                    })}
                </ul>
                }
            </Col>
            <Col md={12} lg={7}>
                <div className="content">
                    <ReactMarkdown rehypePlugins={[rehypeRaw]} children={props.ContentRight}/>
                </div>
            </Col>
        </TextColumns>
        </div>
    )
}

export default HomeTextColumns
